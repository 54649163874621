import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/zolka/Workspace/zolbayars.github.io/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "projects"
    }}>{`Projects`}</h1>
    <p>{`This page contains only content that has the tag `}<strong parentName="p">{`project`}</strong>{`.`}</p>
    <p>{`This works thanks to this code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<FeedItems filterByTags={['project']} />
`}</code></pre>
    <p>{`More about the `}<inlineCode parentName="p">{`FeedItems`}</inlineCode>{` component in the docs: `}<a parentName="p" {...{
        "href": "https://github.com/Chronoblog/gatsby-theme-chronoblog#feeditems-component"
      }}>{`gatsby-theme-chronoblog#feeditems-component`}</a></p>
    <p>{`This is convenient for creating pages with your works, portfolio, collecting articles of a certain subject in one place, etc.`}</p>
    <p>{`If you do not need an extra page, just remove it from `}<inlineCode parentName="p">{`src/pages/`}</inlineCode>{`, and remove link to this page from the main menu: `}<inlineCode parentName="p">{`src/gatsby-theme-chronoblog/site-header.mdx`}</inlineCode>{`.`}</p>
    <p>{`More about the additional site pages in the docs: `}<a parentName="p" {...{
        "href": "https://github.com/Chronoblog/gatsby-theme-chronoblog#pages"
      }}>{`gatsby-theme-chronoblog#pages`}</a></p>
    <h2 {...{
      "id": "projects-any-content-with-the-project-tag"
    }}>{`Projects (any content with the "project" tag):`}</h2>
    <FeedItems filterByTags={['project']} mdxType="FeedItems" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      